import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { featureFlags } from '@constants/common';
import {
  orderItemStatusValueEnum,
  orderStatusEnum,
} from '@constants/enums/orderEnums';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';
import { paymentMethodAllowsChargeAhead } from '@util/paymentHelper';

const changeArtworkAllowedStatuses = [
  orderStatusEnum.Initial,
  orderStatusEnum.ArtworkTasksProcessing,
  orderStatusEnum.ArtworkTasksCompleted,
];

class OrderManagementDetailsItemActions extends PureComponent {
  openReplaceBySkuModal = () => {
    const {
      openReplaceItemBySkuModal,
      item,
    } = this.props;

    openReplaceItemBySkuModal(item);
  };

  openReplaceItemModal = () => {
    const {
      openReplaceItemModal,
      item,
    } = this.props;

    openReplaceItemModal(item);
  };

  openChangeDecorationModal = () => {
    const {
      openChangeDecorationMethodModal,
      item,
    } = this.props;

    openChangeDecorationMethodModal(item);
  };

  cancelOnClickWithRefund = () => {
    const {
      item,
      refundItem,
    } = this.props;

    refundItem(item, true);
  };

  refundOnClick = () => {
    const {
      item,
      refundItem,
    } = this.props;

    refundItem(item, false);
  };

  changeArtwork = () => {
    const {
      changeArtwork,
      item,
    } = this.props;

    changeArtwork(item);
  };

  changeFlaggedArtwork = () => {
    const { item } = this.props;

    this.changeArtwork(item, true);
  };

  hasFlaggedArtwork = () => {
    const {
      item,
      orderFlags,
    } = this.props;

    let hasFlaggedArtwork = false;
    const artwork = item.logos.find((art) => !art.isCanceled);
    if (artwork) {
      const ids = artwork.ids.filter(
        (orderItemDecorationId) => orderFlags?.find(
          (flag) => flag.orderItemDecorationId === orderItemDecorationId && flag.type === flagTypeEnum.WrongArtwork
        ) !== undefined
      );
      if (ids && ids.length > 0) {
        hasFlaggedArtwork = true;
      }
    }

    return hasFlaggedArtwork;
  };

  openRemovePersonalizationModal = () => {
    const {
      openRemovePersonalizationModal,
      item,
    } = this.props;

    openRemovePersonalizationModal(item);
  };

  editPersonalizationOnClick = () => {
    const {
      item,
      editPersonalization,
    } = this.props;

    editPersonalization(item);
  };

  canChangeDecorationMethod = () => {
    const { item } = this.props;

    const hasHagDecorationMethod = item.logos.every((logo) => logo.decorationMethod === decorationMethodEnum.HAG);
    const hasEmbDecorationMethod = item.logos.every((logo) => logo.decorationMethod === decorationMethodEnum.EMB);

    const hagToDtgUpdate = item.styleDecorationMethod === decorationMethodEnum.DTG && hasHagDecorationMethod;
    const embToHagUpdate = item.styleDecorationMethod === decorationMethodEnum.HAG && hasEmbDecorationMethod;

    return hagToDtgUpdate || embToHagUpdate;
  };

  render() {
    const {
      item,
      order,
      shipmentNumbersList,
      isCanceled,
      addPersonalization,
      isOrderCompleteRefund,
      itemVariation,
      orderExistsOnCore,
      itemCorePricingDetails,
    } = this.props;

    const hasFlaggedArtwork = this.hasFlaggedArtwork();

    const hasActivePersonalizations = item.personalizations.some((element) => !element.isCanceled);

    const hasMorePossiblePersonalizations
      = (item?.custom || item?.personalizations?.length === 0)
      // If does not exist on core, allow any available personalizations
      && (!orderExistsOnCore || (item?.personalizations?.length < itemVariation?.item?.personalizations?.length));

    let hideItemCancelingOption = false;
    if (item?.status?.value >= orderItemStatusValueEnum.InPicking) {
      hideItemCancelingOption = true;
    }

    const isRefundableItem = orderExistsOnCore
      ? itemCorePricingDetails?.pricePaid !== 0
      : item?.pricePaid !== 0
      && !item?.isCompleteRefund;

    const itemCanBeModified =
      !item.vendorFulfilledItem
      || (item.vendorFulfilledItem &&
        item.vendorOrderId === null);

    return (
      <div>
        <ShowMoreActionsButton
          actions={[
            {
              text: 'Replace Item',
              action: this.openReplaceItemModal,
              isVisible: !isCanceled
                && itemCanBeModified
                && item.status.value < orderItemStatusValueEnum.InPicking
                && paymentMethodAllowsChargeAhead(order.stripePaymentMethod),
            },
            {
              text: 'Replace Item by SKU',
              action: this.openReplaceBySkuModal,
              isVisible: !isCanceled
                && itemCanBeModified
                && item.status.value < orderItemStatusValueEnum.InPicking
                && paymentMethodAllowsChargeAhead(order.stripePaymentMethod),
            },
            {
              text: 'Change Artwork',
              action: this.changeArtwork,
              isVisible: !isCanceled
                && !item.custom
                && itemCanBeModified
                && changeArtworkAllowedStatuses.includes(order.status.status),
            },
            {
              text: 'Change Flagged Artwork',
              action: this.changeArtwork,
              isVisible: hasFlaggedArtwork && !isCanceled,
            },
            {
              text: 'Change Decoration Method',
              action: this.openChangeDecorationModal,
              isVisible: this.canChangeDecorationMethod(),
            },
            {
              text: 'Add Personalization',
              action: addPersonalization,
              isVisible: hasMorePossiblePersonalizations
                && !isCanceled
                && itemCanBeModified
                && item.status.value < orderItemStatusValueEnum.Assembled
                && paymentMethodAllowsChargeAhead(order.stripePaymentMethod),
            },
            {
              text: 'Edit Personalization(s)',
              action: this.editPersonalizationOnClick,
              isVisible: hasActivePersonalizations
                && !isCanceled
                && itemCanBeModified
                && item.status.value < orderItemStatusValueEnum.Assembled,
            },
            {
              text: 'Remove Personalization',
              action: this.openRemovePersonalizationModal,
              isVisible: hasActivePersonalizations
                && !isCanceled
                && !item.custom
                && itemCanBeModified
                && item.status.value < orderItemStatusValueEnum.Assembled,
            },
            {
              text: 'Refund',
              action: this.refundOnClick,
              isVisible: (!shipmentNumbersList || shipmentNumbersList.length === 0)
                && isRefundableItem && featureFlags.orderItemRefundEnabled
                && !isCanceled
                && !isOrderCompleteRefund
                && !item.voucherId,
              permissions: [PermissionsEnum.OrderManagementEditRefundOrder],
            },
            {
              text: 'Cancel Item',
              action: this.cancelOnClickWithRefund,
              isVisible: (!shipmentNumbersList || shipmentNumbersList.length === 0)
                && !hideItemCancelingOption
                && !isCanceled
                && itemCanBeModified,
              hasBottomBorder: false,
              permissions: [PermissionsEnum.OrderManagementEditCancelOrder],
              isDangerous: true,
            },
          ]}
        />
      </div>
    );
  }
}

OrderManagementDetailsItemActions.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.shape({
      label: PropTypes.string,
    }),
  }).isRequired,
  item: PropTypes.object.isRequired,
  openReplaceItemBySkuModal: PropTypes.func.isRequired,
  openReplaceItemModal: PropTypes.func.isRequired,
  editPersonalization: PropTypes.func.isRequired,
  addPersonalization: PropTypes.func.isRequired,
  openRemovePersonalizationModal: PropTypes.func.isRequired,
  openChangeDecorationMethodModal: PropTypes.func.isRequired,
  refundItem: PropTypes.func.isRequired,
  changeArtwork: PropTypes.func.isRequired,
  shipmentNumbersList: PropTypes.arrayOf(PropTypes.string),
  orderFlags: PropTypes.array,
  isCanceled: PropTypes.bool,
  isOrderCompleteRefund: PropTypes.bool.isRequired,
  itemVariation: PropTypes.object,
  orderExistsOnCore: PropTypes.bool,
  itemCorePricingDetails: PropTypes.object,
};

export default connect()(OrderManagementDetailsItemActions);
